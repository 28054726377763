/**
 * Provides track analysis functionality.
 *
 * Takes the detailed way tags from brouter-server's response
 * and creates tables with distributions of way types, surfaces,
 * and smoothness values.
 *
 * On hovering/click a table row the corresponding track segments
 * are highlighted on the map.
 *
 * @type {L.Class}
 */
BR.TrackTrails = L.Class.extend({
    /**
     * @type {Object}
     */
    options: {
        overlayStyle: {
            color: 'yellow',
            opacity: 0.8,
            weight: 8,
            // show above quality coding (pane defined in RoutingPathQuality.js)
            pane: 'routingQualityPane',
        },
    },

    /**
     * The total distance of the whole track, recalculate on each `update()` call.
     *
     * @type {float}
     */
    totalRouteDistance: 0.0,

    /**
     * @param {Map} map
     * @param {object} options
     */
    initialize: function (map, options) {
        this.map = map;
        L.setOptions(this, options);
    },

    /**
     * @type {?BR.TrackEdges}
     */
    trackEdges: null,

    /**
     * @type {?L.Polyline}
     */
    trackPolyline: null,

    /**
     * true when tab is shown, false when hidden
     *
     * @type {boolean}
     */
    active: false,

    /**
     * Called by BR.Sidebar when tab is activated
     */
    show: function () {
        this.active = true;
        this.options.requestUpdate(this);
    },

    /**
     * Called by BR.Sidebar when tab is deactivated
     */
    hide: function () {
        this.active = false;
    },

    /**
     * Called by user login/logout when trails are updated
     */
    updateTrails: function (userTrails, tracksLoaderControl, routeLoaderControl) {
        if (!this.active || !userTrails || userTrails.length === 0) {
            $('#track_trails').html('').append(
                $('<h4 class="track-analysis-heading">' + i18next.t('warning.user-trails-empty') + '</h4>')
            );
            return;
        }
    
        var self = this;
        var $content = $('#track_trails');
        $content.empty();
    
        // Add SYNC button
        var $syncButton = this.createSyncButton();
        $content.append($syncButton);
    
        // Add Share button
        var $shareButton = $('<button class="btn btn-primary mb-2" id="shareButton" disabled><i class="fa fa-share"></i> Share</button>');
  
        $content.append($shareButton);
    
        var $list = $('<ul class="list-group mt-3"></ul>');
        userTrails.forEach(function (trail) {
            var $listItem = self.createListItem(trail, tracksLoaderControl, routeLoaderControl, $shareButton);
            $list.append($listItem);
        });
    
        $content.append($list);
    
        // Add Share button event
        $shareButton.on('click', function () {
            var $activeItem = $list.find('li.active');
            if ($activeItem.length > 0) {
                var fileName = $activeItem.data('filename');
                var shareUrl = 'https://altlas-app.com/trails/' + fileName;
                if (navigator.share) {
                    navigator.share({
                        title: 'Trail',
                        text: 'Check out this trail!',
                        url: shareUrl,
                    }).then(() => {
                        console.log('Thanks for sharing!');
                    }).catch(console.error);
                } else {
                    console.log('Sharing not supported by the browser');
                }
            } else {
                console.log('No trail selected to share');
            }
        });
    
        console.log('Trail list updated');
    },
    
    createSyncButton: function () {
        // var self = this;
        // var $syncButton = $('<button class="btn btn-primary mb-2" id="shareButton"><i class="fas fa-sync"></i> SYNC</button>');
    
        // $syncButton.prop('disabled', true).on('click', function () {
        //     self.showSyncModal();
        // });
    
        // return $syncButton;
    },
    
    createListItem: function (trail, tracksLoaderControl, routeLoaderControl, shareButton) {
        var self = this;
        var fileNameParts = trail.fileName.split('_');
        var nameWithExtension = fileNameParts[fileNameParts.length - 1];
        var name = nameWithExtension.replace('.json', '');
    
        var $listItem = $('<li class="list-group-item list-group-item-action"></li>').text(name).data('filename', trail.fileName);
    
        $listItem.on('click', function () {
            $(this).siblings().removeClass('active');
            $(this).addClass('active');
            shareButton.prop('disabled', false); 
            self.onTrailClick(name, trail, tracksLoaderControl, routeLoaderControl);
        });
    
        return $listItem;
    },
    
    showSyncModal: function () {
        var $modal = $(
            '<div class="modal fade" tabindex="-1" role="dialog">' +
                '<div class="modal-dialog" role="document">' +
                    '<div class="modal-content">' +
                        '<div class="modal-header">' +
                            '<h5 class="modal-title">Sync Trail</h5>' +
                            '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
                                '<span aria-hidden="true">&times;</span>' +
                            '</button>' +
                        '</div>' +
                        '<div class="modal-body">' +
                            '<div class="form-group">' +
                                '<label for="trailName">Trail Name</label>' +
                                '<input type="text" class="form-control" id="trailName" placeholder="Enter trail name">' +
                            '</div>' +
                        '</div>' +
                        '<div class="modal-footer">' +
                            '<button type="button" class="btn btn-primary" id="syncModalButton">Sync</button>' +
                            '<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>'
        );
    
        $modal.modal('show');
    
        $modal.find('#syncModalButton').on('click', function () {
            var trailName = $modal.find('#trailName').val();
            if (trailName) {
                console.log('Syncing trail:', trailName);
                $modal.modal('hide');
            } else {
                console.log('Please enter a trail name');
            }
        });
    
        $modal.on('hidden.bs.modal', function () {
            $modal.remove();
        });
    },
    

    onTrailClick: function (name, trail, tracksLoaderControl, routeLoaderControl) {
        // 1. Fetch the trail with GET from the server in GPX format BR.conf.overpassBaseUrl
        // 2. Render the trail on the map
        // 3. Highlight the trail in the list
        // 4. Show the trail details in the sidebar

        const fetchUrlGet = `${BR.conf.altlasServerUrl}/get-gpx?fname=${trail.fileName}`;
        console.log('Fetching trail: ' + fetchUrlGet);

        fetch(fetchUrlGet)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                console.log(response);
                return response.blob(); // Convert response to Blob
            })
            .then((blob) => {
                const file = new File([blob], trail.fileName, { type: 'application/gpx+xml' });
                // tracksLoaderControl.loadGpxFile(file);
                routeLoaderControl.loadGpxFile(file);
                return blob.text();
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error;
            });
    },

    /**
     * Everytime the track changes this method is called:
     *
     * - calculate statistics (way type, surface, smoothness)
     *   for the whole track
     * - renders statistics tables
     * - create event listeners which allow to hover/click a
     *   table row for highlighting matching track segments
     *
     * @param {Polyline} polyline
     * @param {Array} segments
     */

    update: function (polyline, segments) {
        if (!this.active) {
            $('#track_trails').html('');
            return;
        }
    },

    /**
     * @param {Object} analysis
     */
    render: function (analysis) {
        console.log('Track Trails render');
    },

    /**
     * Renders an analysis table.
     *
     * @param {string} type
     * @param {Array} data
     * @returns {jQuery}
     */
    renderTable: function (type, data) {},
});
