class MongoSearchItem {
    constructor(data) {
        this.id = data.id || '';
        this.fileName = data.file_name || '';
    }
}

class FirebaseAuth {
    constructor() {
        const firebaseConfig = {
            apiKey: 'AIzaSyBGwS1AFY0EKht4Q6qFawo8Uei5UlaxjR0',
            authDomain: 'altimeter-alarm.firebaseapp.com',
            databaseURL: 'https://altimeter-alarm.firebaseio.com',
            projectId: 'altimeter-alarm',
            storageBucket: 'altimeter-alarm.appspot.com',
            messagingSenderId: '401005041310',
            appId: '1:401005041310:web:70603d769b55e9c0068d8f',
            measurementId: 'G-ZG6JQ186VN',
        };
        firebase.initializeApp(firebaseConfig);

        this.auth = firebase.auth();
        this.ui = new firebaseui.auth.AuthUI(this.auth);
        this.eventListeners = {};
        this.trails = [];

        this.uiConfig = {
            signInFlow: 'popup',
            signInSuccessUrl: '/',
            signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
            callbacks: {
                signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                    const user = firebase.auth().currentUser;
                    console.log('User signed in:', user);
                    this.updateUI(user);
                    return true;
                },
                signInFailure: function (error) {
                    console.log('signInFailure:', error);

                    if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
                        return Promise.resolve();
                    }
                    var cred = error.credential;
                    return firebase.auth().signInWithCredential(cred);
                },
            },
        };

        this.initializeListeners();
        this.initFirebaseUI();
        const signOutButton = document.getElementById('sign-out-button');
        signOutButton.addEventListener('click', () => this.signOut());
    }

    on(event, callback) {
        if (!this.eventListeners[event]) {
            this.eventListeners[event] = [];
        }
        this.eventListeners[event].push(callback);
    }

    emit(event, data) {
        if (this.eventListeners[event]) {
            this.eventListeners[event].forEach((callback) => callback(data));
        }
    }

    updateUI(user) {
        if (user) {
            this.handleLoggedIn(user);
        } else {
            this.handleLoggedOut();
        }
    }

    handleLoggedIn(user) {
        const signOutButton = document.getElementById('sign-out-button');
        this.ui.reset();
        signOutButton.textContent = `Sign Out (${user.displayName})`;
        signOutButton.style.display = 'inline-block'; // Show the sign-out button
        console.log('User signed in:', user.displayName);

        this.getUserTrails(user.uid)
            .then((data) => {
                const items = data.items.map((item) => new MongoSearchItem(item));
                this.trails = items;
                console.log('User trails:', items);
                this.emit('trailsChange', items);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    getUserTrails(uid) {
        const url = `https://api.altlas-app.com/get-user-trails?uid=${uid}&dateMillis=${0}`;

        return fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                var ans = response.json();
                return ans;
            })
            .then((data) => {
                // Assuming data is in JSON format
                return data; // Return the parsed JSON data
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                throw error; // Rethrow the error for handling elsewhere
            });
    }

    handleLoggedOut() {
        const signOutButton = document.getElementById('sign-out-button');
        this.initFirebaseUI();
        signOutButton.style.display = 'none'; // Hide the sign-out button
        console.log('Current user is null :(');
    }

    initFirebaseUI() {
        this.ui.start('#firebaseui-auth-container', this.uiConfig);
    }

    initializeListeners() {
        const auth = firebase.auth();
        auth.onAuthStateChanged((user) => {
            this.updateUI(user);
        });
    }

    signOut() {
        this.auth
            .signOut()
            .then(() => {
                console.log('User signed out');
            })
            .catch((error) => {
                console.error('Error during sign out:', error);
            });
    }

    getCurrentUser() {
        return firebase.auth().currentUser;
    }
}

// Initialize Firebase

// document.addEventListener('DOMContentLoaded', () => {
//     firebase.initializeApp(firebaseConfig);
//     const firebaseAuth = new FirebaseAuth(); //todo move this to index... / 1. pass to TrackTrails 2. 3. on update  firebaseAuth.getUserTrails()
// });
